@import "@/assets/style/_index.scss";















































































































































































































































































































.refund-status {
    /*padding: 0.3rem 0;*/
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 0.36rem;
    > .f-icon {
        margin-right: $margin;
    }
}
